import React from "react";
import { Route, Link } from "react-router-dom";
import LastArticlesList from './LastArticlesList';
import News from '../News'

const News_one = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
            Sztuczna inteligencja uchroni uprawy przed szkodnikami
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    <article>
                        <header class="mb-4">
                            <h1 class="fw-bolder mb-1">
                            Sztuczna inteligencja uchroni uprawy przed szkodnikami
                            </h1>
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 12 lutego 2020</div>
                        </header>
                        <div class="ratio ratio-16x9">
                            <video controls preload className="mx-auto" poster="/img/articles/2075531414_SmartGrow_rosliny_1_s.jpg" width="100%">
                            <source src="/img/articles/2075531414_SmartGrow_rosliny_1_s.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                        <small className="my-2 d-block fw-light">
                         [Źródło filmu: http://innowacje.newseria.pl/news/sztuczna-inteligencja,p2075531414]
                        </small> 
                        <section class="mb-5">
                            <p class="fs-5 my-4">
                            Nowe technologie coraz skuteczniej wspierają pracę rolników. Dzięki inteligentnej
                            platformie tworzonej przez polski start-up uda się uchronić uprawy przed działaniem
                            szkodników poprzez stosowanie chemicznych oprysków wyłącznie w miejscach, w
                            których jest to konieczne.
                            </p>
                            
                            <hr className="my-5 d-block" />
                            <p class="fs-5 mb-2">
                                Więcej informacji z tego wydarzenia:
                            </p>
                            <a href="https://innowacje.newseria.pl/news/sztuczna-inteligencja,p2075531414/" target="_blank" class="fs-5 mb-5">
                            https://innowacje.newseria.pl/news/sztuczna-inteligencja,p2075531414
                            </a>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                <div class="col-lg-3 ps-4">

                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <LastArticlesList />

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_one;