import React from "react";
import GalleryComponent from "../components/lightGallery";

const Gallery = () => {

  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Galeria</h1>

      <div className="container mb-5">
        <GalleryComponent />
      </div>
  
    </>
  )
}

export default Gallery;
