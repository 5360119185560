import React from "react";

const MediaAboutUs = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Media o nas</h1>

      <div class="container-lg p-lg-5">

        <div className="mb-5">
          <img src="https://www.jagodnik.pl/wp-content/uploads/2015/09/logo_final_only.png" alt="Jagodnik" class="flex-shrink-0" width="150" height="auto" />
          <hr />
          <a href="https://jagodnik.pl/rosalita-w-uprawie-pod-oslonami-uprawa-z-sadzonek-long-cane/" target="_blank">
            <p>https://jagodnik.pl/rosalita-w-uprawie-pod-oslonami-uprawa-z-sadzonek-long-cane/</p>
          </a> 
          <a href="https://jagodnik.pl/system-zarzadzania-warunkami-uprawy-borowek-i-nie-tylko/" target="_blank">
            <p>https://jagodnik.pl/system-zarzadzania-warunkami-uprawy-borowek-i-nie-tylko/</p>
          </a> 
          <a href="https://www.youtube.com/watch?v=C5yGfdYoeF0" target="_blank">
            <p>https://www.youtube.com/watch?v=C5yGfdYoeF0</p>
          </a> 
        </div>

        <div className="mb-5">
          <img src="https://mamstartup.pl/wp-content/uploads/2021/06/mamstartup-logo-invert-1.svg" alt="MamStartup" class="flex-shrink-0" width="150" height="auto" />
          <hr />
          <a href="https://mamstartup.pl/tag/smartgrow/" target="_blank">
            <p>https://mamstartup.pl/tag/smartgrow/</p>
          </a> 
        </div>

        <div className="mb-5">
          <img src="https://innowacje.newseria.pl/grafika/st3.gif" alt="Newseria - Innowacje" class="flex-shrink-0" width="160" height="auto" />
          <hr />
          <a href="https://innowacje.newseria.pl/news/sztuczna-inteligencja,p2075531414" target="_blank">
            <p>https://innowacje.newseria.pl/news/sztuczna-inteligencja,p2075531414</p>
          </a> 
        </div>

        <div className="mb-5">
          <img src="https://unicornhub.pl/wp-content/uploads/2019/01/logo.svg" alt="Unicorn Hub" class="flex-shrink-0" width="100" height="auto" />
          <hr />
          <a href="https://unicornhub.pl/smartgrow-sp-z-o-o/" target="_blank">
            <p>https://unicornhub.pl/smartgrow-sp-z-o-o/</p>
          </a> 
        </div>

      </div>

    </>
  )
}

export default MediaAboutUs;