import React from "react";

const System = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">System SmartGrow</h1>
    
      
      <div className="container">

        <p className="h3 my-4">Nasza misja</p>
        <p className="lead fw-normal mt-4 mb-5">
          Dostarczyć plantatorowi precyzyjną  informację wspierającą podejmowanie optymalnej decyzji w uprawie roślin.
        </p>

        <p className="h3 my-4">Kilka podstawowych informacji o Systemie Smartgrow</p>
        <p className="lead fw-normal mt-4 mb-5">
          Cały System składa się z wielu modułów analizujących środowisko rozwoju roślin zawierających szereg 
          specjalistycznych czujników mierzących parametry otoczenia środowiska, automatycznej cyfrowej 
          feromonowej pułapki oraz  platformy obliczeniowej  Amazon Web Services, która przesyła ważne 
          informacje dla plantatora na jego smartfon i komputer.
        </p>
        <figure className="px-lg-5 mx-lg-5 shadow-sm">
          <img class="img-fluid rounded px-lg-5" src="/img/optymalizacja_upraw.png" alt="..." />
        </figure>
        <br />
        <figure className="px-lg-5 mx-lg-5 mt-lg-5 shadow-sm">
          <img class="img-fluid rounded px-lg-5" src="/img/optymalizacja_upraw_2.png" alt="..." />
        </figure>
        <br />
        <p className="h4 fw-normal mt-5 mb-5">
          <span class="badge bg-smartgrow me-4">1</span>
          SmartGrow to innowacyjny system zintegrowanych modułowych urządzeń pomiarowych, które w sposób 
          istotny i wymierny wspierają optymalizację procesu upraw roślin.
        </p>
        <p className="h4 fw-normal mt-4 mb-5">
          <span class="badge bg-smartgrow me-4">2</span>
          SmartGrow to system urządzeń, które ze sobą współpracują  jako moduły mające wyspecjalizowane zadania pomiarowe odczytujące najważniejsze zmiany w otoczeniu środowiska rozwoju roślin.
        </p>
        <p className="h4 fw-normal mt-4 mb-5">
          <span class="badge bg-smartgrow me-4">3</span>
          System SmartGrow  może pełnić wiele zadań na plantacjach w celu optymalizacji upraw roślin:
        </p>
        <p className="lead fw-normal mt-4 mb-5">
          <span className="mt-3 ps-5 d-block">
            <b>Pierwsze zadanie: </b> System SmartGrow jest wsparciem dla plantatora  aby optymalnie dopasować opryski ochronne roślin dzięki zastosowaniu modułu  cyfrowej automatycznej feromonowej pułapki.
          </span>
          <span className="mt-3 ps-5 d-block">
            
            <b>Drugie zadanie: </b> System Smartgrow jest wsparciem dla plantatora aby racjonalnie i ekonomicznie stosować nawożenie i nawodnienie dzięki  zastosowaniu modułu sterowania nawodnieniem.
          </span>
          <span className="mt-3 ps-5 d-block">
            
            <b>Trzecie zadanie: </b> System SmartGrow   jest wsparciem dla plantatora aby  korzystnie ekonomicznie zastosować schematy  prowadzenia uprawy roślin – umożliwia to  zastosowanie modułu analizy środowiska rośliny.
          </span>
          <span className="mt-3 ps-5 d-block">
            <b>Czwarte zadanie: </b> System SmartGrow  posiada moduł Smart Farm, którego celem jest wsparcie dla plantatora aby racjonalnie i ekonomicznie zarządzać gospodarstwem oraz osiągać ponadprzeciętne wyniki finansowe oszczędzając czas pracy i środki do produkcji rolnej.
          </span>

          <p className="lead fw-normal mt-5 mb-5">
            Obecnie opracowujemy inne moduły naszego Systemu SmartGrow, które mają na celu wsparcie plantatorów w ich pracy, dostarczyć im precyzyjną  informację wspierającą plantatora w podejmowaniu jak  najlepszej  decyzji  w  procesie uprawy roślin.
          </p>
        </p>
        <hr />
        <p className="text-center my-5">
          Więcej o możliwościach i o zadaniach jakie może pełnić System SmartGrow  na plantacjach w celu optymalizacji upraw roślin  podczas szczegółowej prezentacji i dyskusji merytorycznej.
        </p>
      </div>
    </>
  )
}

export default System;