import React from "react";
import { Link } from "react-router-dom";

const Fertilization = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Nawożenie</h1>

      <div class="container-lg p-lg-5">
        <ul>
          <li className="list-unstyled mb-3">
            -<span className="ms-3">Preparaty Grena</span>
          </li>
          <li className="list-unstyled mb-3">
            -<span className="ms-3">Doradztwo</span>
          </li>
          <li className="list-unstyled mb-3">
            -<span className="ms-3">Budowanie schematów nawożeniowych</span>
          </li>
          <li className="list-unstyled mb-3">
            -
            <span className="ms-3">
              Ekologiczne rozwiązania uprawy roślin
            </span>
          </li>
        </ul>
      </div>

    </>
  )
}

export default Fertilization;