import React from "react";

const About = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">O nas</h1>

      
      <section class="bg-white">
        <div class="container px-5 my-5">
          <h2 class="fw-bolder mb-5 text-center">Nasza misja</h2>
          <h2 class="fw-bolder mb-5 text-center">Dostarczyć plantatorowi precyzyjną informację wspierającą
podejmowanie optymalnej decyzji w uprawie roślin.</h2>
          <p class="lead fw-bolder mb-5 text-center">Trendy we współczesnym rolnictwie, które są dla naszej firmy inspiracją:</p>
          <p className="lead fw-normal mt-4 mb-3">
          1.We współczesnym rolnictwie następuje intensywny wzrost znaczenia zastosowania
informatyki i narzędzi  cyfrowych w celu zwiększenia ilości i jakości produkcji rolnej,
wpływając na dynamiczny rozwój rolnictwa  precyzyjnego i cyfrowego na świecie.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        2. Zmniejsza się areał powierzchni przeznaczonej do upraw i zwiększa się liczba
ludności - należy zatem  poszukiwać metod i narzędzi wykorzystanych do procesu
aktywnego i pełnego wykorzystania powierzchni  plantacji.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        3. Następuje proces zagrożenia środowiska naturalnego, jego zasobów a wśród nich
szczególnie zasobów  czystej wody zarówno tej powierzchniowej, jak i gruntowej.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        4. Poszukiwanie rozwiązań technologicznych dla firm z obszaru agro jest
wyzwaniem dla tworzenia innowacyjnych metod, technologii i  narzędzi do
zastosowania we współczesnym rolnictwie.
        </p>


          <p className="h3 mt-5 my-4">O nas</p> 
          <p className="lead fw-normal mt-4 mb-3">
          Nasza misja jest głęboko przemyślana i związana jest z naszymi doświadczeniami w pracy
na rodzinnej kilkupokoleniowej plantacji i wykorzystaniu w procesie upraw roślin najnowszej
wiedzy i rozwiązań technologicznych, które wpływają na wzrost plonów i zarazem
racjonalizują koszty związane z pracą na roli.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Poprzez nasze rozwiązania, które są sprawdzone na naszych plantacjach a także u tych
plantatorów, którzy nam zaufali i zastosowali nasz System, chcemy dostarczać plantatorom
takie urządzenia, które będą ich wspierać w ich codziennej ciężkiej i odpowiedzialnej pracy.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Współczesny rolnik zarządza skomplikowanym przedsiębiorstwem i codziennie musi
rozwiązywać na bieżąco wiele spraw i podejmować decyzje pod dużą presją czasu i
zarazem pojawiających się wielu niewiadomych. Plantator nie może sobie pozwolić na błędne czy
też nietrafione decyzje, na nie precyzyjną czy też przestarzałą technologie w swoich
urządzeniach, które pracują na jego plantacji.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Dlatego chcemy oferować takie rozwiązania , które będą wsparciem w codziennej pracy
rolnika, który dla nas staje się Partnerem we wzajemnej współpracy nad doskonaleniem
narzędzi wspierających pracę rolnika.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Każdy rolnik dostrzega ,że we współczesnym rolnictwie następuje   intensywny wzrost
znaczenia zastosowania informatyki i narzędzi cyfrowych w celu zwiększenia ilości i jakości
produkcji rolnej.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        W Polsce i w świecie zmniejsza się areał   powierzchni przeznaczonej do upraw - należy
zatem poszukiwać metod i narzędzi wykorzystanych do procesu   aktywnego i pełnego
wykorzystania powierzchni plantacji.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Dlatego też nasza firma – dzięki naszym współpracownikom - łączy wiedzę i
doświadczenie z upraw roślin oraz wiedzę technologiczną i informatyczną  w celu
tworzenia innowacyjnych metod i narzędzi do zastosowania we współczesnym rolnictwie.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        Stworzyliśmy nasz System SmartGrow, który analizuje fizyczne parametry wpływające na
wzrost roślin na plantacji w czasie rzeczywistym i poprzez to umożliwia plantatorowi
podejmowanie optymalnej i racjonalnej decyzji aby wpływać na właściwy proces uprawy i
uzyskiwać jak najlepsze plony a jednocześnie zmniejszać koszty na plantacji.
        </p>
        <p className="lead fw-normal mt-4 mb-3">
        W naszej firmie obecnie trwają dalsze prace badawczo wdrożeniowe nad nowymi
urządzeniami i narzędziami mającymi zastosowanie we współczesnym rolnictwie, które będą
wspomagać plantatora w jego pracy zgodnie z naszą misją.
        </p>
        </div>
      </section>

      <section class="pt-3 pb-3 bg-white">
                <div class="container px-5 my-5">
                    <div class="text-center">
                        <h2 class="fw-bolder mb-5">Zarząd</h2>
                    </div>
                    <div class="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-fluid rounded-circle mb-4 px-4" src="/img/jerzy-rosiak-150x150.png" alt="..." />
                                <h5 class="fw-bolder">Jerzy Rosiak</h5>
                                <div class="fst-italic text-muted">Prezes</div>
                            </div>
                        </div>
                        <div class="col mb-5 mb-5 mb-xl-0">
                            <div class="text-center">
                                <img class="img-fluid rounded-circle mb-4 px-4" src="/img/grzgeorz-piech-150x150.png" alt="..." />
                                <h5 class="fw-bolder">Grzegorz Piech</h5>
                                <div class="fst-italic text-muted">Wiceprezes <br /> Główny technolog</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      
    </>
  )
}

export default About;
