import React from "react";

const Partnership = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Współpraca</h1>

      <div class="container-lg p-lg-5 mt-4">

      <ul class="nav nav-tabs border-none d-flex align-items-center justify-content-between w-100 mb-4" id="partnershipTab" role="tablist">
        <li class="nav-item" Style="width: 49.5%" role="presentation">
          <button class="nav-link w-100 lead py-lg-3 px-lg-5 active" id="left-tab" data-bs-toggle="tab" data-bs-target="#left" type="button" role="tab" aria-controls="left" aria-selected="true">Propozycja współpracy z plantatorami</button>
        </li>
        <li class="nav-item" Style="width: 49.5%" role="presentation">
          <button class="nav-link w-100 lead py-lg-3 px-lg-5" id="right-tab" data-bs-toggle="tab" data-bs-target="#right" type="button" role="tab" aria-controls="right" aria-selected="false">Propozycja współpracy z inwestorami</button>
        </li>
      </ul>
      <div class="tab-content mb-5" id="partnershipTabContent">
        <div class="tab-pane fade show active px-4" id="left" role="tabpanel" aria-labelledby="left-tab">
          
          <h1 className="fw-normal pt-5">Propozycja współpracy z plantatorami</h1>
      
          <p className="lead fw-normal my-4 mt-5">
            Jesteśmy jako firma SmartGrow Sp. z o.o. gotowi do wsparcia w prowadzeniu plantacji w uprawach
            roślin jagodowych -głównie malin i borówki amerykańskiej.
          </p>

          <p className="lead fw-normal my-4">
            Plantacja może być umiejscowiona w każdym miejscu w Polsce.
          </p>
          <p className="lead fw-normal my-4">
            Nasze doradztwo będzie realizowane poprzez zastosowanie naszego Systemu Smartgrow, dzięki
            któremu jesteśmy w stanie na ,,odległość” prowadzić uprawę, analizując cały proces uprawy roślin.
            System Smartgrow jest tak zaawansowany technologicznie i informatycznie, że umożliwia
            pozyskiwanie różnorodnych informacji z urządzeń umieszczonym na plantacji. Jest to zaawansowana
            technologia informatyczna, dzięki której poprzez przekaz danych uzyskanych z plantacji do naszego
            cyfrowego systemu obliczeniowego zainstalowanych na serwerach Amazon Wes Services oraz ich
            biologicznej analizie przez doświadczonego doradcę, udzielamy ważnych informacji dla plantatora i
            zalecamy mu wykonanie konkretnych czynności, aby proces uprawy przebiegał prawidłowo. Dzięki
            zastosowaniu naszego Systemu mamy pełny obraz procesu rozwoju rośliny i dostrzegamy każde
            niekorzystne zjawisko jakie pojawi się na plantacji już w fazie jego zalążkowego, początkowego
            powstawania. Wtedy interweniujemy aby je usunąć aby rośliny na plantacji były w procesie
            prawidłowego rozwoju i owocowania.
          </p>
          <p className="lead fw-normal my-4">
          Jest także możliwość osobistej konsultacji doradcy na plantacji.
          </p>
          <p className="lead fw-normal my-4">
            Nasza wiedza i doświadczenie 25 letnie w uprawach, upoważniają nas do zaproponowania
            współpracy polegającej na doradztwie w prowadzeniu procesu upraw roślin jagodowych – głównie
            malin i borówki amerykańskiej i równocześnie wykorzystaniu naszego Systemu Smartgrow do
            racjonalnego i ekonomicznego zarządzania plantacją.
          </p>
          <p className="lead fw-normal my-4">
            Taka plantacja będzie prowadzona zgodnie z najbardziej rygorystycznymi zasadami ekologicznych
            upraw i efekt jest taki, że owoce byłyby wolne od obciążeń chemicznych.
          </p>
          
          <p className="lead fw-normal my-4">
            Na naszych uprawach takie wyniki zostały osiągnięte – analiza badań w laboratoriach w Republice
            Federalnej Niemiec to potwierdziła na zlecenie firmy Lidl, do których sieci supermarketów nasze
            maliny są dostarczane jako deserowe premium.
          </p>
          <p className="lead fw-normal my-4">
            Mając na uwadze coraz większe zainteresowanie odbiorców owocami najwyższej jakości
            uprawianymi metodami ekologicznymi, dostrzegamy i doświadczamy rosnącą potrzebę rynkową na
            tego typu produkty.
          </p>
          <p className="lead fw-normal my-4">
            W sezonie można zebrać – w zależności od wielu zmiennych pojawiających się podczas procesu
            upraw – od 10 do 15 ton malin z 1 ha
          </p>
          <p className="lead fw-normal my-4">
            Aby uniknąć wielu problemów z pracownikami zaangażowanych do zbioru malin – do zbioru
            zalecamy ( w zależności od powierzchni upraw ), aby był wykorzystany specjalistyczny kombajn.
          </p>
          <p className="lead fw-normal my-4">
            Szczegóły kosztów założenia i prowadzenia plantacji malin w przeliczeniu na 1h wymagają
            indywidualnej kalkulacji związanej z wieloma aspektami.
          </p>
          <p className="h4 my-5">
            Plantator zainteresowany objęciem jego plantacji naszym doradztwem,<br /> proszony jest o kontakt
            telefoniczny oraz o wypełnienie ankiety i przesłanie jej na nasz adres e-mail.
          </p>

        </div>
        <div class="tab-pane fade px-4" id="right" role="tabpanel" aria-labelledby="right-tab">
          
        
        <h1 className="fw-normal pt-5">Oferta współpracy z inwestorami chcącymi posiadać swoją plantację.</h1>
      
          <p className="lead fw-normal my-4 mt-5">
            Jesteśmy jako firma SmartGrow Sp. z o.o. gotowi do wsparcia w zagospodarowaniu powierzchni
            uprawowej, którą dysponuje inwestor zainteresowany w powstanie i rozwój dynamicznie
            rozwijanego oraz dochodowego przedsięwzięcia w każdym miejscu na terenie Polski, jak i poza jej
            obszarem.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Nasza wiedza i doświadczenie 25 letnie w uprawach, upoważniają nas do zaproponowania
współpracy polegającej na doradztwie w prowadzeniu procesu upraw roślin jagodowych – głównie
malin i borówki amerykańskiej i równocześnie wykorzystaniu naszego Systemu Smartgrow do
ekologicznego procesu upraw roślin oraz racjonalnego i ekonomicznego zarządzania plantacją.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Nasze wsparcie będzie miało miejsce bezpośrednio na plantacji Inwestora oraz pośrednio poprzez
zastosowanie naszego Systemu Smartgrow, dzięki któremu jesteśmy w stanie na ,,odległość”
prowadzić uprawę, analizując cały proces uprawy roślin. System Smartgrow jest tak zaawansowany
technologicznie i informatycznie, że umożliwia pozyskiwanie różnorodnych informacji z urządzeń
umieszczonym na plantacji. Jest to zaawansowana technologia informatyczna, dzięki której poprzez
przekaz danych uzyskanych z plantacji do naszego cyfrowego systemu obliczeniowego
zainstalowanych na serwerach Amazon Web Services oraz ich biologicznej analizie przez
doświadczonego doradcę, udzielamy ważnych informacji dla plantatora i zalecamy mu wykonanie
konkretnych czynności, aby proces uprawy przebiegał prawidłowo. Dzięki zastosowaniu naszego
Systemu mamy pełny obraz procesu rozwoju rośliny i dostrzegamy każde niekorzystne zjawisko jakie
pojawi się na plantacji już w fazie jego zalążkowego, początkowego powstawania. Wtedy
interweniujemy aby je usunąć aby rośliny na plantacji były w procesie prawidłowego rozwoju i
owocowania.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Taka plantacja będzie prowadzona zgodnie z najbardziej rygorystycznymi zasadami ekologicznych
upraw i efekt jest taki, że owoce byłyby wolne od obciążeń chemicznych.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Na naszych uprawach takie wyniki zostały osiągnięte – analiza badań w laboratoriach w Republice
Federalnej Niemiec to potwierdziła na zlecenie firmy Lidl, do których sieci supermarketów nasze
maliny są dostarczane jako deserowe premium.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Mając na uwadze coraz większe zainteresowanie odbiorców owocami najwyższej jakości
uprawianymi metodami ekologicznymi, dostrzegamy i doświadczamy rosnącą potrzebę rynkową na
tego typu produkty.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Aby uniknąć wielu problemów z pracownikami zaangażowanych do zbioru malin – do zbioru byłby
wykorzystany kombajn.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Dla inwestora będzie przygotowana szczegółowa analiza kosztów założenia i prowadzenia plantacji
malin w przeliczeniu na 1h uwzględniająca wiele aspektów przedsięwzięcia.
          </p>
          <p className="lead fw-normal my-4 mt-5">
          Nadmieniamy, że w sezonie można zebrać – w zależności od wielu zmiennych pojawiających się
podczas procesu upraw – od 10 do 15 ton malin z 1 ha
          </p>
          <p className="h4 my-5">
          Inwestor zainteresowany współpracą w realizacji jego przedsięwzięcia w rozwój jego plantacji,
proszony jest o kontakt telefoniczny oraz o wypełnienie ankiety i przesłanie jej na nasz adres e-mail.
          </p>

        </div>
      </div>





        

      </div>

    </>
  )
}

export default Partnership;