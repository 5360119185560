import React from "react";

const MedicalCannabis = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Konopie siewne</h1>

      <section class="pt-3 pb-3 bg-white">
                <div class="container px-5 my-5">
                  <h3 class="fw-bolder mb-3">Dlaczego warto uprawiać konopie</h3>
                  <p className="lead fw-normal mt-4 mb-3 ms-5">
                  Polsce konopie jeszcze do lat 60. XX wieku były szeroko uprawiane a przed
II wojną światową była to dosyć popularna roślina uprawna w naszym kraju.
Później uprawa konopi przestała się opłacać z różnych powodów nie tylko w
Polsce ale w większości krajów na świecie ( proszę wejść na załączone linki).
Jednak wciągu ostatnich lat nastąpił niezwykły renesans w uprawie konopi i
zastosowaniu jej do różnych celów – od przemysłu do kosmetologii i
medycyny.
Rynek upraw rośnie dynamicznie a zarazem równocześnie dynamicznie
rośnie rynek przetwarzający konopie np. z kwiatostanów konopi i nasion w
szerokim zakresie produkty z CBD w aptekach i w drogeriach kosmetyki a w
przemyśle ma wielostronne zastosowanie( proszę wejść na załączone linki).
        </p>
                  <p className="lead fw-normal mt-4 mb-1 ms-5">
                    Warto zwrócić uwagę na te dwie prognozy:
                  </p>
                  <p className="lead fw-bolder mt-4 mb-1 ms-5">
                    Wartość europejskiego rynku konopi w 2027 r. wzrośnie do 36 mld dol.
                  </p>
                  <p className="lead fw-bolder mt-4 mb-1 ms-5">
                    Rynek medycznej marihuany w Polsce w 2028 r. będzie wart 2 mld euro
                  </p>
                  <p className="lead fw-bolder mt-5 mb-1">
                  Wybrane linki, które warto przeczytać:
                  </p>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Spodnie Levi Straussa i karoseria Forda. Na ból zębów, bezsenność i gojenie ran –
niesamowita historia przemysłu konopnego
                  </p>
                  <a className="lead fw-bolder mt-4 mb-1" href="https://businessinsider.com.pl/finanse/handel/historia-przemyslu-konopi-na-swiecie/xkp22gy" target="_blank">
                  https://businessinsider.com.pl/finanse/handel/historia-przemyslu-konopi-na-swiecie/xkp22gy
                  </a>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Konopie warte miliardy. Oto jak Polska staje się konopnym zagłębiem
                  </p>
                  <a className="lead fw-bolder mt-4 mb-1" href="https://businessinsider.com.pl/firmy/strategie/rynek-konopii-w-polsce-medyczna-marihuana-wartosc-firmy/nfb6vnc" target="_blank">
                  https://businessinsider.com.pl/firmy/strategie/rynek-konopii-w-polsce-medyczna-marihuana-wartosc-firmy/nfb6vnc
                  </a>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Branża marihuany może być najszybciej rosnącą obecnie branżą w Stanach Zjednoczonych
                  </p>
                  <a className="lead fw-bolder mt-4 mb-1" href="https://businessinsider.com.pl/firmy/sprzedaz/marihuana-jako-rosnaca-branza-w-usa/6jxcb2f" target="_blank">
                  https://businessinsider.com.pl/firmy/sprzedaz/marihuana-jako-rosnaca-branza-w-usa/6jxcb2f
                  </a>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Czy uprawa konopi się opłaca? I to jak!
                  </p>
                  <a className="lead fw-bolder mt-4 mb-1" href="https://agronews.com.pl/artykul/czy-uprawa-konopi-sie-oplaca-i-to-jak/" target="_blank">
                  https://agronews.com.pl/artykul/czy-uprawa-konopi-sie-oplaca-i-to-jak/
                  </a>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Lecznicza marihuana będzie dostępna w polskich aptekach
                  </p>
                  <a className="lead fw-bolder mt-4 mb-1" href="https://businessinsider.com.pl/wiadomosci/lecznicza-marihuana-w-polsce/t1cfq0v" target="_blank">
                  Lecznicza marihuana w Polsce (businessinsider.com.pl)
                  </a>
                  <br />
                  <a className="lead fw-bolder mt-4 mb-1" href="https://businessinsider.com.pl/wiadomosci/lecznicza-marihuana-w-polsce/t1cfq0v" target="_blank">
                  https://businessinsider.com.pl/wiadomosci/lecznicza-marihuana-w-polsce/t1cfq0v
                  </a>
                  <p className="lead fw-bolder mt-4 mb-1">
                  Rośnie zainteresowanie uprawą konopi w Polsce
                  </p>
                  <a className="lead fw-bolder mb-5 d-block" href="https://businessinsider.com.pl/wiadomosci/uprawa-konopi-w-polsce-rosnie-zainteresowanie-ale-prawo-hamuje-rozwoj/3xd0e2d" target="_blank">
                  https://businessinsider.com.pl/wiadomosci/uprawa-konopi-w-polsce-rosnie-zainteresowanie-ale-prawo-hamuje-rozwoj/3xd0e2d
                  </a>
                  <hr />
                  <h3 class="fw-bolder mt-5 mb-4">Oferta współpracy z inwestorami chcącymi posiadać swoją
plantację konopi siewnej.</h3>
                  <p className="lead fw-normal mt-4 mb-3">
                    Oferta skierowana jest do osób, które chcą taką plantacje posiadać i nie mają
                    doświadczenia w uprawach roślin ale posiadają fundusze, które mogą zainwestować
                    w dochodowe przedsięwzięcie w każdym miejscu na terenie Polski, jak i poza jej
                    obszarem. Dla Inwestora przedstawimy model biznesowy dla tego przedsięwzięcia.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Rynek konopi – od upraw po uzyskane z ich kwiatostanów i nasion - różnych
                  substancji, jest rynkiem niezwykle dynamicznie się rozwijającym i atrakcyjnie
                  finansowym.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Jesteśmy jako firma SmartGrow Sp. z o.o. przygotowani i gotowi do wsparcia w
                  zagospodarowaniu powierzchni uprawowej, którą dysponuje inwestor
                  zainteresowany posiadaniem takiej plantacji upraw konopi siewnej oraz jej
                  prowadzeniu.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza wiedza i doświadczenie 5 letnie w uprawach konopi siewnej, upoważniają nas do
zaproponowania współpracy z plantatorami polegającej na doradztwie w prowadzeniu
procesu upraw konopi siewnej i równoczesnego wykorzystania naszego Systemu Smartgrow
do racjonalnego i ekonomicznego zarządzania plantacją konopi siewnej – szczególnie w
uprawie indorowej i w pod osłonami.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza współpraca z Inwestorem będzie miała miejsce bezpośrednio na plantacji
Inwestora oraz pośrednio poprzez zastosowanie naszego Systemu Smartgrow,
dzięki któremu jesteśmy w stanie na ,,odległość” prowadzić uprawę, analizując i
kontrolując cały proces uprawy konopi.. System Smartgrow jest tak zaawansowany
technologicznie i informatycznie, że umożliwia pozyskiwanie różnorodnych informacji
z urządzeń umieszczonym na plantacji.. Dzięki zastosowaniu naszego Systemu
mamy pełny obraz procesu rozwoju rośliny i dostrzegamy każde niekorzystne
zjawisko jakie pojawi się na plantacji już w fazie jego zalążkowego, początkowego
powstawania.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Poprzez ten System uzyskujemy szereg ważnych informacji , które są sygnałem do
wykonania konkretnych czynności, aby proces uprawy konopi przebiegał
prawidłowo.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Taka plantacja będzie prowadzona zgodnie z najbardziej rygorystycznymi zasadami
upraw konopi aby został osiągnięty cel – zbiory kwiatostanu i nasion konopi miały
oczekiwany poziom CBD.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Dla Inwestora będzie przygotowana szczegółowa analiza kosztów założenia i
prowadzenia plantacji konopi na proponowanej powierzchni, która uwzględnia wiele
aspektów tego przedsięwzięcia.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Inwestor zainteresowany współpracą w realizacji jego przedsięwzięcia, prowadzenie i
rozwój jego plantacji konopi, proszony jest o kontakt telefoniczny oraz o wypełnienie
ankiety i przesłanie jej na nasz adres e-mail.
                  </p>

                  <hr />
                  <h3 class="fw-bolder mt-5 mb-4">Doradztwo w prowadzeniu plantacji konopi siewnej.</h3>
                  <p className="lead fw-normal mt-4 mb-3">
                  Oferta skierowana jest do plantatorów posiadających lub chcących założyć plantację konopi
i oczekujących uzyskania satysfakcjonujących wyników z jej prowadzenia a także aby
zdynamizować jej rozwój i efektywność finansową.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Jesteśmy jako firma SmartGrow Sp. z o.o. przygotowani do wsparcia w prowadzeniu
plantacji w uprawach konopi siewnej.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza forma doradztwa , wsparcie plantatora w jego pracy, jest głęboko przemyślane i jest
związane z naszymi doświadczeniami w pracy na rodzinnej kilkupokoleniowej plantacji oraz
wykorzystaniu w procesie upraw konopi siewnej najnowszej wiedzy i rozwiązań
technologicznych, które wpływają na wzrost plonów i zarazem racjonalizują koszty związane
z jej uprawą w warunkach upraw w szklarni, pod osłonami, indorowej oraz w uprawie w
gruncie.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza wiedza i doświadczenie 5 letnie w uprawach konopi siewnej, upoważniają nas do
zaproponowania współpracy z plantatorami polegającej na doradztwie w prowadzeniu
procesu upraw konopi siewnej i równoczesnego wykorzystania naszego Systemu Smartgrow
do racjonalnego i ekonomicznego zarządzania plantacją konopi siewnej – szczególnie w
uprawie indorowej i w pod osłonami.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Poprzez nasze rozwiązania, które są sprawdzone na naszych plantacjach a także u tych
plantatorów, którzy nam zaufali i zastosowali nasze metody i narzędzia związane z
profesjonalnym prowadzeniem plantacji, możemy podjąć się pomocy i wsparcia dla
początkujących, jak i już doświadczonych plantatorów.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Mamy zasadę iż dzielenie się wiedzą i doświadczeniem jest procesem rozwoju innych osób
dla ich dobra a dla naszej satysfakcji płynącej z tego faktu.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Doradztwo polega na udzieleniu ważnych informacji dla plantatora we wzajemnej
współpracy poprzez zastosowanie naszego Systemu SmartGrow i osobistej naszej
obecności na plantacji.
                  </p>

                  <ol>
                    <li className="lead fw-normal mt-4 mb-3">
                      <p>
                      Plantator uzyskuje istotne informacje i różne danych ze swojej plantacji konopi
siewnej poprzez nasz System Smartgrow oraz dzięki biologicznej analizie dokonanej
przez doświadczonego doradcę. Analiza tych danych umożliwia udzielenie ważnych
informacji dla plantatora oraz są one podstawą do tworzenia programu prowadzenia
konkretnej plantacji.
                      </p>
                      <p>
                      Program ten zawiera zalecane i konieczne wykonanie konkretnych czynności w
określonym czasie – terminach oraz porach dnia , aby proces uprawy konopi siewnej
przebiegał prawidłowo.
                      </p>
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Poprzez zastosowanie naszego Systemu mamy pełny obraz procesu rozwoju konopi
siewnej i dostrzegamy każde niekorzystne zjawisko jakie pojawi się na plantacji już w
fazie jego zalążkowego, początkowego powstawania. Wtedy interweniujemy aby je
usunąć aby rośliny na plantacji były w procesie prawidłowego rozwoju i owocowania.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Poprzez nasze doradztwo będzie możliwość osiągnięcia takiego efektu iż plantacja
pod naszą opieką i doradztwem będzie prowadzona zgodnie z najbardziej
rygorystycznymi zasadami upraw aby osiągnąć oczekiwany efekt ekonomiczny, że
owoce będą osiągać ponadprzeciętne wskaźniki CBD
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Dzięki naszemu Systemowi plantacja może być umiejscowiona w każdej części
Polski.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Na powierzonej nam plantacji możemy w celu prawidłowej uprawy możemy
zainstalować nasz Smartgrow System Nawadniania – SSN 1 wraz z całościową
instalacją nawodnieniową.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Wykonujemy projekt nawadniania plantacji i nasi pracownicy mogą wykonać
całościową instalację.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Szczegóły kosztów prowadzenia plantacji konopi siewnej wymagają indywidualnej
kalkulacji związanej z wieloma aspektami.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Plantator zainteresowany objęciem jego plantacji naszym doradztwem,
proszony jest o kontakt telefoniczny oraz o wypełnienie ankiety i przesłanie jej na
nasz adres e-mail.
                    </li>
                  </ol>
                </div>
            </section>

    </>
  )
}

export default MedicalCannabis;