import React from "react";
import { Link } from "react-router-dom";

const Projects_PARP = () => {
  return(
    <>
    <br />
    <br />
      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container-lg mx-5">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/projekty">Projekty</Link></li>
            <li class="breadcrumb-item active" aria-current="page">PARP - "SmartGrow - zabezpiecz swoje uprawy"</li>
          </ol>
        </div>
      </nav>
      <div className="container-lg mb-5 p-5  ">
        <h1 class="fw-light mt-4 mb-5">
          PARP - "SmartGrow - zabezpiecz swoje uprawy"
        </h1>
        <h3 class="fw-bolder my-5">Projekt - &quot;SmartGrow - zabezpiecz swoje uprawy&quot;</h3>
        <p className="lead fw-normal mt-4 mb-1">
        Projekt SmartGrow - zabezpiecz swoje uprawy&quot; jest dofinansowany z Polskiej Agencji
Rozwoju i Przedsiębiorczości
        </p>
        <p className="lead fw-normal my-4">
        Smartgrow Sp. z o. o jako star up uzyskał z Polskiej Agencji Rozwoju i Przedsiębiorczości
1 mln zł. dofinasowania na rozwój swojej innowacji technologicznej,  która odpowiada na
wyzwania z obszaru współczesnego rolnictwa precyzyjnego i cyfrowego.
        </p>
        <img className="border col-12 col-md-9 col-lg-8 my-2" alt="Fundusze Europejskie - loga" src="../img/FE_poziom_pl-1_rgb.jpg" />

        <p className="lead fw-normal mt-4 mb-1">
        Beneficjent: SmartGrow Sp. z o.o.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Wartość projektu: 1 266 754, 50 zł, dofinansowanie projektu 999 948,50 zł.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Projekt współfinansowany przez Unię Europejską z Europejskiego Funduszu Rozwoju
        Regionalnego w ramach Programu Operacyjnego Polska Wschodnia 2014-2020, Osi
        priorytetowej I: Przedsiębiorcza Polska Wschodnia, Działania 1.1 Platformy startowe dla
        nowych pomysłów, Podziałania 1.1.2 Rozwój startupów w Polsce Wschodniej.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Celem projektu jest rozwinięcie działalności biznesowej przedsiębiorstwa SmartGrow Spółka
        z o.o. w oparciu o innowacyjny produkt oraz model biznesowy przygotowany w ramach
        podziałania 1.1.1 POPW. W ramach projektu planowane jest rozpoczęcie produkcji urządzeń
        modułowych Systemu SmartGrow , które będą istotnym wsparciem dla plantatora aby
        optymalnie dopasować opryski ochronne roślin  dzięki zastosowaniu modułu automatycznej
        cyfrowej feromonowej pułapki na szkodniki roślin i stworzenie chmury obliczeniowej oraz
        aplikacji do określania stopnia zagrożenia plantacji przez ilość szkodników zintegrowanej z
        zewnętrznym modułem analizy środowiska wzrostu rośliny w jeden spójny system.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Planowane efekty projektu:
        </p>
       
        <ul>
          <li className="lead fw-normal my-2">
          Testowe wdrażanie systemu
          </li>
          <li className="lead fw-normal my-2">
          rozpoczęcie produkcji;
          </li>
          <li className="lead fw-normal my-2">
          zaprojektowanie, stworzenie oraz wdrożenie innowacyjnego systemu ( moduł analizy
środowiska wzrostu rośliny, moduł cyfrowej autonomicznej pułapki cyfrowej,
oprogramowania do analiz w chmurze obliczeniowej na AWS oraz aplikacja).
          </li>
        </ul>

        <hr className="my-5" />
        <p className="lead fw-bold mt-4 mb-1">
        Trendy we współczesnym rolnictwie, które są dla naszej firmy inspiracją:
        </p>

        <ol>
          <li className="lead fw-normal mt-4 mb-3">
          We współczesnym rolnictwie następuje intensywny wzrost znaczenia zastosowania
informatyki i narzędzi  cyfrowych w celu zwiększenia ilości i jakości produkcji rolnej,
wpływając na dynamiczny rozwój rolnictwa  precyzyjnego i cyfrowego na świecie.
          </li>
          <li className="lead fw-normal mt-4 mb-3">
          Zmniejsza się areał powierzchni przeznaczonej do upraw i zwiększa się liczba
ludności - należy zatem  poszukiwać metod i narzędzi wykorzystanych do procesu
aktywnego i pełnego wykorzystania powierzchni  plantacji.
          </li>
          <li className="lead fw-normal mt-4 mb-3">
          Następuje proces zagrożenia środowiska naturalnego, jego zasobów a wśród nich
szczególnie zasobów  czystej wody zarówno tej powierzchniowej, jak i gruntowej.
          </li>
          <li className="lead fw-normal mt-4 mb-3">
          Poszukiwanie rozwiązań technologicznych dla firm z obszaru agro jest
wyzwaniem dla tworzenia innowacyjnych metod, technologii i  narzędzi do
zastosowania w rolnictwie.
          </li>
        </ol>

        <p className="lead fw-normal mt-4 mb-1">
        Kilka słów o powstaniu naszej innowacji -jesteśmy spółką start up, która uczestniczyła
w projekcie „Platforma Startowa – Unicorn Hub, współfinansowanego ze środków Unii
Europejskiej a realizowanego przez Polską Fundację Ośrodków Wspomagania Rozwoju
Gospodarczego „OIC POLAND z siedzibą w Lublinie, lidera projektu oraz kilku
podmiotów jako partnerów. (strona &nbsp;
<a href="www.unicomhorn.pl" target="_blank">
    www.unicomhorn.pl
</a> ) <br />
Przeszliśmy kilku miesięczny okres inkubacji, przygotowując pełną dokumentację
projektową związaną z rozwojem naszego pomysłu. W trakcie inkubacji poczyniliśmy
praktyczne działania w celu utworzenia narzędzi do naszego pomysłu wchodzące w
skład Systemu SmartGrow - więcej o tych narzędziach poniżej. W trakcie realizacji
projektu kilku miesięczne testowanie potwierdza nasze założenia i kierunek dalszego
rozwoju Systemu o dalsze moduły oraz zastosowania go na plantacjach a także w
różnych obszarach otoczenia biznesu agro.
        </p>

        <p className="lead fw-bold mt-4 mb-1">
        Podstawowe informacje o Systemie SmartGrow
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        W naszej firmie jest realny potencjał, aby tworzyć w pełni innowacyjne i
nowoczesne narzędzia, które będą miały zastosowanie w rolnictwie
precyzyjnym i w rolnictwie cyfrowym.
        </p>
        <p className="lead fw-normal mt-4 mb-4">
        Nasze innowacyjne rozwiązania technologiczne wynikają z naszej wiedzy i
przeszło 25 letniego  doświadczenia w prowadzeniu upraw na własnej
kilkupokoleniowej plantacji, gdzie tworzyliśmy swoje własne metody i  narzędzia
służące do jak najbardziej optymalnego procesu uprawy roślin.
        </p>
        <img className="col-11 col-md-6 col-lg-4 m-2" alt="" src="../img/logo-hero.jpg" />
        <p className="lead fw-normal mt-4 mb-1">
        Opracowany przez Smartgrow Sp. z o.o. System dotyczy opomiarowania i
optymalizacji procesu uprawy  roślin, racjonalizacji zużycia wody, nawozów i środków
ochrony roślin. Zastosowane w tym systemie  urządzenia, technologie i narzędzia
informatyczne są na najwyższym poziomie technologicznym a modułowa budowa 
Systemu umożliwia jego zastosowanie do wykonywania szeregu pomiarów w
środowisku naturalnym.
        </p>
        <hr />
        <p className="lead fw-normal mt-4 mb-1">
        System SmartGrow składa się z wielu specjalistycznych modułów zawierające
nowoczesne i sprawdzone  urządzenia i narzędzia technologiczne oraz informatyczne
zdolne pozyskiwać i zarazem analizować różne  parametry środowisko rozwoju roślin ,
jak i szeroko rozumianego środowiska naturalnego. Pozyskane dane  są przesyłane do
platformy obliczeniowej Amazon Web Services, która je analizuje, tworząc zbiór
przetworzonych i oczekiwanych informacji a następnie przesyła je do określonego
odbiorcy np. plantatora na jego  smartfon i komputer czy odbiorcę instytucjonalnego.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Smartgrow Sp. z o.o tworzy innowacyjne narzędzia i rozwiązania technologiczne
na rzecz rozwoju rolnictwa  precyzyjnego, cyfrowego oraz wykorzystania narzędzi
i technologii informatycznych w opomiarowaniu i  monitorowaniu szeroko
rozumianego środowiska wzrostu roślin.
        </p>
        <hr />
        <p className="lead fw-normal mt-4 mb-1">
        System Smartgrow jest pozytywnie oceniony przez wiele instytucji naukowo-
badawczych, plantatorów i jest w  procedowaniu patentowym
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Cały System składa się z wielu modułów analizujących środowisko rozwoju roślin
zawierających szereg specjalistycznych czujników mierzących parametry otoczenia
środowiska, automatycznej cyfrowej feromonowej pułapki oraz platformy obliczeniowej
umieszczonej na Amazon Web Services. Zebrane dane z obszaru  plantacji są
przesyłane na indywidualne konta plantatorów, które znajdują się na platformie
obliczeniowej  SmartGrow.
        </p>
        <hr />
        <p className="lead fw-bold mt-4 mb-1">
        System SmartGrow może pełnić wiele zadań na plantacjach w celu optymalizacji upraw
roślin, np.:
        </p>

        <ol>
          <li className="lead fw-normal mt-4 mb-3">
          System SmartGrow jest wsparciem dla plantatora aby optymalnie dopasować
opryski ochronne roślin  dzięki zastosowaniu modułu cyfrowej automatycznej
feromonowej pułapki na szkodniki.
          </li>
          <li className="lead fw-normal mt-4 mb-3">
          System Smartgrow jest wsparciem dla plantatora aby racjonalnie i ekonomicznie
stosować nawodnienie  dzięki zastosowaniu modułu sterowania nawodnieniem.
          </li>
          <li className="lead fw-normal mt-4 mb-3">
          System SmartGrow jest wsparciem dla plantatora aby korzystnie ekonomicznie
stosować prowadzenia  uprawy roślin – umożliwia to zastosowanie modułu analizy
środowiska roślin.
          </li>
        </ol>
        
        <p className="lead fw-normal mt-4 mb-1">
        System Smartgrow, dzięki zastosowaniu nowoczesnych i sprawdzonych urządzeń i
narzędzi technologicznych oraz informatycznych umożliwia pełną, w czasie
rzeczywistym, cyfrowe monitorowanie przemieszczania się azotu w glebie i w wodzie.
Dzięki temu można monitorować całe środowisko przyrodnicze i to  w sposób ciągły,
otrzymując w każdej chwili analizę z danego miejsca, która jest jednocześnie
zapisywana  w systemie informatycznym.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        Dane te mogą stanowić podstawę do różnych analiz i badań środowiska
przyrodniczego.
        </p>
        <p className="lead fw-normal mt-4 mb-1">
        System SmartGrow, może być - po opracowaniu nowego modułu i oprogramowania
informatycznego - narzędziem do mapowania w czasie rzeczywistym migracji azotu w
glebie i w  wodzie i poprzez to może być istotnym wsparciem dla realizacji Dyrektywy
Azotanowej, która wymusza na rolnikach i wielu instytucji działań monitorujących
migrację azotu.
        </p>
        <img className="col-11 col-md-6 col-lg-4 mt-4 mb-2" alt="" src="../img/logo-hero.jpg" />
        <p className="lead fw-normal mb-1">
        Nad tym rozwiązaniem w naszej firmie obecnie pracujemy.
        </p>

      </div>

    </>
  )
}

export default Projects_PARP;