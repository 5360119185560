import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';

function GalleryComponent() {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <div className="App">
            <p className="h3 mt-5 mb-4">Borówkowe Factory</p>
            <LightGallery
                onInit={onInit}
                speed={500}
                zoom = {false}
                download = {false}
                plugins={[lgThumbnail]}
                >
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 1" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 2" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 3" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 4" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 5" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 6" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 7" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-5 col-lg-3 m-2" alt="Opis 8" src="img/logo-hero.jpg" />
                </a>
            </LightGallery>

            <hr className="my-5" />

            <p className="h3 mb-4">Malinowe Factory</p>
            <LightGallery
                onInit={onInit}
                speed={500}
                zoom = {false}
                download = {false}
                plugins={[lgThumbnail]}
            >
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 1" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 2" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 3" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 4" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 5" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 6" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 7" src="img/logo-hero.jpg" />
                </a>
                <a href="img/logo-hero.jpg">
                    <img className="border col-11 col-md-6 col-lg-3 m-2" alt="Opiss 8" src="img/logo-hero.jpg" />
                </a>
            </LightGallery>

        </div>
    );
}

export default GalleryComponent;