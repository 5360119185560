import React from "react";
import { Route, Link } from "react-router-dom";
import LastArticlesList from './LastArticlesList';
import News from '../News'

const News_two = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
            5 startupów z sektora rolno-spożywczego, które warto obserwować
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    <article>
                        <header class="mb-4">
                            <h1 class="fw-bolder mb-1">
                            5 startupów z sektora rolno-spożywczego, które warto obserwować
                            </h1>
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 12 września 2021</div>
                        </header>
                        <section class="mb-5">
                            <p class="fs-5 mb-4">
                                Nasza firma Smartgrow Sp. z o. o znalazła się wśród 5 startapów z sektora rolno-
                                spożywczego, które w skali Europy moją bardzo obiecującą i perspektywiczną
                                innowację technologiczną na rynku agro.
                            </p>
                            <hr className="my-5 d-block" />
                            <p class="fs-5 mb-2">
                            Więcej na:
                            </p>
                            <a href="https://mamstartup.pl/5-startupow-z-sektora-rolno-spozywczego-ktore-warto-obserwowac/" target="_blank" class="fs-5 mb-5">
                            https://mamstartup.pl/5-startupow-z-sektora-rolno-spozywczego-ktore-warto-obserwowac/
                            </a>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                <div class="col-lg-3 ps-4">
                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <LastArticlesList />

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_two;