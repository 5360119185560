import React from "react";

const Consulting = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Doradztwo</h1>

      <section class="pt-3 pb-3 bg-white">
                <div class="container px-5 my-5">
                  <h3 class="fw-bolder mt-5 mb-4">Doradztwo w prowadzeniu plantacji konopi siewnej.</h3>
                  <p className="lead fw-normal mt-4 mb-3">
                  Oferta skierowana jest do plantatorów posiadających lub chcących założyć plantację konopi
i oczekujących uzyskania satysfakcjonujących wyników z jej prowadzenia a także aby
zdynamizować jej rozwój i efektywność finansową.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Jesteśmy jako firma SmartGrow Sp. z o.o. przygotowani do wsparcia w prowadzeniu
plantacji w uprawach konopi siewnej.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza forma doradztwa , wsparcie plantatora w jego pracy, jest głęboko przemyślane i jest
związane z naszymi doświadczeniami w pracy na rodzinnej kilkupokoleniowej plantacji oraz
wykorzystaniu w procesie upraw konopi siewnej najnowszej wiedzy i rozwiązań
technologicznych, które wpływają na wzrost plonów i zarazem racjonalizują koszty związane
z jej uprawą w warunkach upraw w szklarni, pod osłonami, indorowej oraz w uprawie w
gruncie.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Nasza wiedza i doświadczenie 5 letnie w uprawach konopi siewnej, upoważniają nas do
zaproponowania współpracy z plantatorami polegającej na doradztwie w prowadzeniu
procesu upraw konopi siewnej i równoczesnego wykorzystania naszego Systemu Smartgrow
do racjonalnego i ekonomicznego zarządzania plantacją konopi siewnej – szczególnie w
uprawie indorowej i w pod osłonami.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Poprzez nasze rozwiązania, które są sprawdzone na naszych plantacjach a także u tych
plantatorów, którzy nam zaufali i zastosowali nasze metody i narzędzia związane z
profesjonalnym prowadzeniem plantacji, możemy podjąć się pomocy i wsparcia dla
początkujących, jak i już doświadczonych plantatorów.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Mamy zasadę iż dzielenie się wiedzą i doświadczeniem jest procesem rozwoju innych osób
dla ich dobra a dla naszej satysfakcji płynącej z tego faktu.
                  </p>
                  <p className="lead fw-normal mt-4 mb-3">
                  Doradztwo polega na udzieleniu ważnych informacji dla plantatora we wzajemnej
współpracy poprzez zastosowanie naszego Systemu SmartGrow i osobistej naszej
obecności na plantacji.
                  </p>

                  <ol>
                    <li className="lead fw-normal mt-4 mb-3">
                      <p>
                      Plantator uzyskuje istotne informacje i różne danych ze swojej plantacji konopi
siewnej poprzez nasz System Smartgrow oraz dzięki biologicznej analizie dokonanej
przez doświadczonego doradcę. Analiza tych danych umożliwia udzielenie ważnych
informacji dla plantatora oraz są one podstawą do tworzenia programu prowadzenia
konkretnej plantacji.
                      </p>
                      <p>
                      Program ten zawiera zalecane i konieczne wykonanie konkretnych czynności w
określonym czasie – terminach oraz porach dnia , aby proces uprawy konopi siewnej
przebiegał prawidłowo.
                      </p>
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Poprzez zastosowanie naszego Systemu mamy pełny obraz procesu rozwoju konopi
siewnej i dostrzegamy każde niekorzystne zjawisko jakie pojawi się na plantacji już w
fazie jego zalążkowego, początkowego powstawania. Wtedy interweniujemy aby je
usunąć aby rośliny na plantacji były w procesie prawidłowego rozwoju i owocowania.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Poprzez nasze doradztwo będzie możliwość osiągnięcia takiego efektu iż plantacja
pod naszą opieką i doradztwem będzie prowadzona zgodnie z najbardziej
rygorystycznymi zasadami upraw aby osiągnąć oczekiwany efekt ekonomiczny, że
owoce będą osiągać ponadprzeciętne wskaźniki CBD
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Dzięki naszemu Systemowi plantacja może być umiejscowiona w każdej części
Polski.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Na powierzonej nam plantacji możemy w celu prawidłowej uprawy możemy
zainstalować nasz Smartgrow System Nawadniania – SSN 1 wraz z całościową
instalacją nawodnieniową.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Wykonujemy projekt nawadniania plantacji i nasi pracownicy mogą wykonać
całościową instalację.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Szczegóły kosztów prowadzenia plantacji konopi siewnej wymagają indywidualnej
kalkulacji związanej z wieloma aspektami.
                    </li>
                    <li className="lead fw-normal mt-4 mb-3">
                    Plantator zainteresowany objęciem jego plantacji naszym doradztwem,
proszony jest o kontakt telefoniczny oraz o wypełnienie ankiety i przesłanie jej na
nasz adres e-mail.
                    </li>
                  </ol>
                </div>
            </section>

    </>
  )
}

export default Consulting;