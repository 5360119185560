const LastArticlesList = () => {
    return (
        <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <a href="/aktualnosci/projekt-borowkowe-faktory-2021" className="text-muted">
                                <small>
                                Projekt Borówkowe Factory
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/aktualnosci/projekt-malinowe-faktory-2021" className="text-muted">
                                <small>
                                Projekt Malinowe Factory 2021
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/aktualnosci/system-zarzadzania-warunkami-uprawy-borowek" className="text-muted">
                                <small>
                                System zarządzania warunkami uprawy borówek
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/aktualnosci/5-startupow-z-sektora-rolno-spozywczego" className="text-muted">
                                <small>
                                5 startupów z sektora rolno-spożywczego, które warto obserwować
                                </small>
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a href="/aktualnosci/sztuczna-inteligencja-uchroni-uprawy-przed-szkodnikami" className="text-muted">
                                <small>
                                Sztuczna inteligencja uchroni uprawy przed szkodnikami
                                </small>
                            </a>
                        </li>

                    </ul>
    );
};

export default LastArticlesList;