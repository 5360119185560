import React from 'react';
import { Link } from "react-router-dom";

const Navbar = () => {
  return(
    <header className="fixed-top">
      <nav className="navbar navbar-light bg-white border-bottom my-0 py-0" id="lang">
        <div className="container">
          <ul className="nav me-auto d-flex align-items-center">
            <a href="#!" className="text-muted mx-2 d-flex align-items-center my-0 py-0 border border-bottom-0" aria-label="Poland">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" height="20" width="32" className="mt-0 pt-0"><path fill="#fafafa" d="M0 0h16v10H0z"/><path fill="#dc143c" d="M0 5h16v5H0z"/></svg>
            </a>
            {/* ../en/index.html */}
            <a href="#!" className="text-muted mx-2 d-flex align-items-center" aria-label="Great Britain">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 20" width="32" height="20">
                <clipPath><path d="M 0,0 V 30 H 60 V 0 Z"/></clipPath>
                <clipPath><path d="m 30,15 h 30 v 15 z m 0,0 V 30 H 0 Z m 0,0 H 0 V 0 Z m 0,0 V 0 h 30 z"/></clipPath>
                <path d="M -0.09966746,0.12360653 V 20.123607 H 31.900333 V 0.12360653 Z" fill="#012169" Style="stroke-width:0.596285" />
                <path d="M -0.09966746,0.12360653 31.900333,20.123607 m 0,-20.00000047 L -0.09966746,20.123607" stroke="#ffffff" stroke-width="5" />
                <path d="M 0,0 60,30 M 60,0 0,30" stroke="#c8102e" stroke-width="4" transform="matrix(0.53333333,0,0,0.66666668,-0.09966746,0.12360653)" />
                <path d="M 15.900333,0.12360653 V 20.123607 m -16.00000046,-10 H 31.900333" stroke="#ffffff" stroke-width="6.5"/>
                <path d="M 15.900333,0.12360653 V 20.123607 m -16.00000046,-10 H 31.900333" stroke="#c8102e" stroke-width="3.57771"/>
              </svg>
            </a>
            <a href="#!" className="text-muted ms-2 d-flex align-items-center" aria-label="Ukraine">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 2" height="20" width="32"><path fill="#0057B7" d="M0 0h3v2H0z"/><path fill="gold" d="M0 1h3v1H0z"/></svg>
            </a>
          </ul>
          <Link to="/projekty/parp-smartgrow-zabezpiecz-swoje-uprawy">
            <img className="d-block my-0 p-0 ms-auto" width="auto" height="40" alt="Unia Europejska" src="../img/140x-logo_ue_pl.jpg" />
          </Link>
        </div>
      </nav>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom shadow-sm py-3 mt-0">
        <div className="container">
        <Link to="/" className="navbar-brand fw-bold ">
          SMARTGROW
          {/* <img src="logo.png" height="70px" /> */}
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item fw-bold dropdown">
                <a className="nav-link dropdown-toggle py-2" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  O firmie
                </a>
                <ul className="dropdown-menu shadow-sm" aria-labelledby="navbarDropdownMenuLink">
                  <li><Link className="dropdown-item" to="/o-nas">O nas</Link></li>
                  <li><Link className="dropdown-item" to="/projekty">Projekty</Link></li>
                  {/* <li><Link className="dropdown-item" to="/galeria">Galeria</Link></li> */}
                  <li><Link className="dropdown-item" to="/media-o-nas">Media o nas</Link></li>
                  {/* <li><Link className="dropdown-item" to="/referencje">Referencje</Link></li> */}
                </ul>
            </li>
            <li className="nav-item fw-bold">
              <Link to="/system" className="nav-link py-2">System Smartgrow</Link>
            </li>
            <li className="nav-item fw-bold dropdown">
              <a className="nav-link dropdown-toggle py-2" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Oferta
              </a>
              <ul className="dropdown-menu shadow-sm" aria-labelledby="navbarDropdownMenuLink">
                {/* <li><Link to="/system" className="dropdown-item">System SmartGrow</Link></li> */}
                <li><Link to="/system-nawadniania" className="dropdown-item">System nawadniania</Link></li>
                <li><Link to="/konopie-siewne" className="dropdown-item">Konopie siewne</Link></li>
                <li><Link to="/doradztwo" className="dropdown-item">Doradztwo</Link></li>
                <li><Link to="/wspolpraca" className="dropdown-item">Współpraca</Link></li>
                {/* <li><Link to="/zarzadzanie-gospodarstwem" className="dropdown-item">Zarządzanie gospodarstwem</Link></li> */}
                {/* <li><Link to="/materialy-do-pobrania" className="dropdown-item">Materiały do pobrania</Link></li> */}
              </ul>
            </li>
            <li className="nav-item fw-bold">
              <Link to="/aktualnosci" className="nav-link py-2">Aktualności</Link>
            </li>
            <li className="nav-item fw-bold">
              <Link to="/kontakt" className="nav-link py-2">Kontakt</Link>
            </li>
          </ul>
          <form className="d-flex flex-column flex-lg-row ms-auto">
            {/* <a href="https://pomiary.smartgrow.pl/login" className="d-grid gap-2 ms-0 ms-lg-2 mx-auto mb-3 mb-lg-0" target="_blank">
              <button className="btn btn-sm btn-smartgrow fw-bold fs-6 px-3 py-1" type="button">Sklep</button>
            </a> */}
            <a href="https://pomiary.smartgrow.pl/login" className="d-grid gap-2 ms-3 ms-lg-2 mx-auto" target="_blank">
              <button className="btn btn-sm btn-outline-secondary fw-bold px-3 py-1" type="button">Logowanie do systemu</button>
            </a>
          </form>
          </div>
        </div>
      </nav>
    </header>

  )
}

export default Navbar;