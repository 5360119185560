import React from "react";
import { Route, Link } from "react-router-dom";
import LastArticlesList from './LastArticlesList';
import News from '../News'

const News_one = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
            Projekt Borówkowe Factory
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    <article>
                        <header class="mb-4">
                            <h1 class="fw-bolder mb-1">
                            Projekt Borówkowe Factory
                            </h1>
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 16 sierpnia 2021</div>
                        </header>
                        <figure class="mb-4">
                            <img className="img-fluid rounded mx-auto d-block w-100" src="/img/articles/DJI_0997BF_2021.jpg" alt="..." />
                            <small className="my-2 d-block fw-light">
                            Borówkowe Factory obyły się 10 września 2021 roku w gospodarstwie dr Pawła Krawca
w Karczmiskach koło Opola Lubelskiego [Źródło zdjęć: jagodnik.pl]
                            </small>  
                        </figure>
                        {/* <!-- Post content--> */}
                        <section class="mb-5">
                            <p class="fs-5 mb-4">
                            Borówkowe Factory obyły się 10 września w gospodarstwie dr Pawła Krawca w
                            Karczmiskach koło Opola Lubelskiego. Dopisała pogoda i goście. Licznie swoją
                            ofertę zaprezentowały firmy oferujące rozwiązania dedykowane borówkowej branży
                            w tym nasza Firma Smartgrow Sp. z o.o. Działo się bardzo dużo – rozmowy
                            dotyczyły zarówno trudnego pod względem handlowym sezonu borówkowego, ale
                            również tegorocznych doświadczeń jakie skupiły się na programach pozwalających
                            zregenerować rośliny po uszkodzeniach gradowych.
                            </p>
                            <hr className="my-5 d-block" />
                            <h4 class="fw-bolder mb-3 mt-5">Stoisko naszej firmy</h4>
                            <p class="fs-5 mb-4">
                                Stoisko firmy SmartGrow wśród innych wystawców podczas Borówkowe Factory - na fotografi poniżej.
                            </p>
                            <img class="img-fluid rounded mx-auto d-block w-100" src="/img/articles/img_2129BF_2021.jpg" alt="..." />  
                            <small className="my-2 d-block fw-light">
                                Stoisko naszej firmy SmartGrow wśród innych wystawców podczas Borówkowe Factory [Źródło zdjęć: jagodnik.pl]

                            </small>
                            <hr className="my-5 d-block" />
                            <h4 class="fw-bolder mb-3 mt-5">Udział naszej firmy</h4>
                            <p class="fs-5 my-4">
                                Grzegorz Piech – Wiceprezes naszej firmy, dokonał prezentacji Systemu Smartgrow
                                dotyczącej zastosowania tego Systemu do uprawy borówki zarówno w tunelach jak też tej w
                                glebie, a w szczególności w substratach w donicach i o znaczeniu monitorowania warunków
                                wzrostu roślin. Nastepnie Grzegorz Piech udzielał wyczerpujących informacji podczas sesji
                                pytań i dyskusji, którą prowadził Pan dr Paweł Krawiec – na fotografi poniżej.
                            </p>
                            <img class="img-fluid rounded mx-auto d-block w-100" src="/img/articles/IMG_1795BF_2021.jpg" alt="..." />  
                            <small className="my-2 d-block fw-light">
                            Grzegorz Piech – Wiceprezes naszej firmy [Źródło zdjęć: jagodnik.pl]
                            </small>
                            <hr className="my-5 d-block" />
                            <p class="fs-5 mb-2">
                                Więcej informacji z tego wydarzenia:
                            </p>
                            <a href="https://jagodnik.pl/finalowe-spotkanie-producentow-borowki/" target="_blank" class="fs-5 mb-5">
                                https://jagodnik.pl/finalowe-spotkanie-producentow-borowki/
                            </a>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                <div class="col-lg-3 ps-4">

                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <LastArticlesList />

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_one;