import React from "react";
import { Link } from "react-router-dom";

const Reference = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5"></div>
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Referencje</h1>

      <div class="container-lg p-lg-5">
        <p className="lead fw-normal text-center">Otrzymaliśmy referencje od następujących instytucji i firm:</p>
          LOGA

        {/* <ul>
          <li className="list-unstyled mb-3">
            <input type="checkbox" /><span className="ms-3">okres inkubacji</span>
          </li>
          <li className="list-unstyled mb-3">
            <input type="checkbox" /><span className="ms-3">IUNG - Instytut Uprawy Nawożenia i Gleboznawstwa</span>
          </li>
          <li className="list-unstyled mb-3">
            <input type="checkbox" /><span className="ms-3">falenty</span>
          </li>
          <li className="list-unstyled mb-3">
            <input type="checkbox" /><span className="ms-3">łyskana</span>
          </li>
        </ul> */}
      </div>

    </>
  )
}

export default Reference;