import React from "react";
import { Route, Link } from "react-router-dom";
import LastArticlesList from './LastArticlesList';
import News from '../News'

const News_two = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
            System zarządzania warunkami uprawy borówek
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    <article>
                        <header class="mb-4">
                            <h1 class="fw-bolder mb-1">
                            System zarządzania warunkami uprawy borówek
                            </h1>
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 16 sierpnia 2021</div>
                        </header>
                        <figure class="mb-4">
                            <iframe width="100%" height="500" src="https://www.youtube.com/embed/C5yGfdYoeF0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <small className="my-2 d-block fw-light">
                            [Źródło filmu: jagodnik.pl]
                            </small>  
                        </figure>
                        <p class="fs-5 mb-4">
                                W początku czerwca 2021 roku w gospodarstwie dr Pawła Krawca z Horti Team
                                doszło do kolejnej ciekawej rozmowy. Jej tematem był system zarządzania
                                warunkami uprawy borówek. Rozwiązanie takie oferuje firma SmartGrow. Będzie ono
                                testowo wdrażane na plantacjach, gdzie realizowane są projekty Borówkowe Factory
                                i Malinowe Factory.
                            </p>

                        <section class="mb-5">
                            <hr className="my-5 d-block" />
                            <p class="fs-5 mb-2">
                                Więcej informacji z przebiegu tego wydarzenia:
                            </p>
                            <a href="https://jagodnik.pl/system-zarzadzania-warunkami-uprawy-borowek-i-nie-tylko/" target="_blank" class="fs-5 mb-5">
                            https://jagodnik.pl/system-zarzadzania-warunkami-uprawy-borowek-i-nie-tylko/
                            </a>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                <div class="col-lg-3 ps-4">

                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <LastArticlesList />

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_two;