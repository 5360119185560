import React from "react";
import { Route, Link } from "react-router-dom";
import LastArticlesList from './LastArticlesList';
import News from '../News'

const News_two = () => {
  return(
    <>
      <br />
      <br />

      <nav className="bg-light" Style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <div className="container mx-5 px-4">
          <ol class="breadcrumb py-2 px-5 mb-5 ">
            <li class="breadcrumb-item"><Link to="/">Strona główna</Link></li>
            <li class="breadcrumb-item"><Link to="/aktualnosci">Aktualności</Link></li>
            <li class="breadcrumb-item active" aria-current="page">
                Projekt Malinowe Factory 2021
            </li>
          </ol>
        </div>
      </nav>

      <div class="container my-5">
            <div class="row">
                <div class="col-lg-9">
                    {/* <!-- Post content--> */}
                    <article>
                        {/* <!-- Post header--> */}
                        <header class="mb-4">
                            {/* <!-- Post title--> */}
                            <h1 class="fw-bolder mb-1">
                            Projekt Malinowe Factory 2021
                            </h1>
                            {/* <!-- Post meta content--> */}
                            <div class="text-muted fst-italic mt-4 mb-1">Opublikowano dnia: 16 sierpnia 2021</div>
                            {/* <!-- Post categories--> */}
                        </header>
                        {/* <!-- Preview image figure--> */}
                        <figure class="mb-4">
                            {/* <img class="img-fluid rounded" src="https://dummyimage.com/900x400/ced4da/6c757d.jpg" alt="..." />   */}
                            <img className="img-fluid rounded mx-auto d-block w-100" src="/img/articles/Malinowe-Factory_.jpg" alt="..." />
                            <small className="my-2 d-block fw-light">
                            Malinowe Factory miał miejsce w dniu 6 sierpnia w nowoczesnym gospodarstwie Agronom Berries w Pasiece koło Sosnowicy. [Źródło zdjęć: jagodnik.pl]
                            </small>  
                        </figure>
                        {/* <!-- Post content--> */}
                        <section class="mb-5">
                            <p class="fs-5 mb-4">
                                Malinowe Factory miał miejsce w dniu 6 sierpnia w nowoczesnym gospodarstwie
                                Agronom Berries w Pasiece koło Sosnowicy. Firma SmartGrow Sp. Z o. o. brała
                                aktywny udział w tym wydarzeniu – mieliśmy swoje stoisko wśród innych wystawców
                                a nasz główny technolog -wiceprezes Grzegorz Piech na forum wszystkich
                                uczestników podczas konferencji w otoczeniu pięknej plantacji malin dokonał
                                prezentacji Systemu SmartGrow. Po jej zakończeniu miała miejsce ożywiona sesja
                                pytań i rozpoczęła się interesująca dyskusja nad naszym innowacyjnym
                                rozwiązaniem oraz odbyło się wiele indywidualnych rozmów i zawieranie wstępnych
                                umów o dostarczeniu naszego Systemu do zainteresowanych plantatorów.
                            </p>

                            <hr className="my-5 d-block" />
                            <p class="fs-5 mb-2">
                                Więcej informacji z przebiegu tego wydarzenia:
                            </p>
                            <a href="https://jagodnik.pl/malinowe-factory-2021-w-nowej-lokalizacji/" target="_blank" class="fs-5 mb-5">
                                https://jagodnik.pl/malinowe-factory-2021-w-nowej-lokalizacji/
                            </a>
                        </section>
                        <Link to="/aktualnosci"><button type="button" class="btn btn-smartgrow btn-sm px-3">Powrót do listy aktualności</button></Link>
                    </article>

                </div>
                {/* <!-- Side widgets--> */}
                <div class="col-lg-3 ps-4">

                    <p className="lead fw-normal mb-2 ps-3 divider-left">Ostatnie wpisy:</p>

                    <LastArticlesList />

                </div>
            </div>
        </div>  

        <Route exact path="/aktualnosci" component={News} />

    </>
  )
}

export default News_two;