import React, { Component } from 'react';
import { Link, useParams } from "react-router-dom";

class News extends Component {
  state = {
    articles: [
      {
        id: '7820211',
        url: '/aktualnosci/projekt-borowkowe-faktory-2021',
        date: '16 września 2021', 
        titleShort: 'Projekt Borówkowe Factory',
        decription: 'Borówkowe Factory obyły się 10 września w gospodarstwie dr Pawła Krawca w Karczmiskach koło Opola Lubelskiego. Dopisała pogoda i goście. Licznie swoją ofertę zaprezentowały firmy oferujące rozwiązania dedykowane borówkowej branży w tym nasza firma.',
      },
      {
        id: '7820212',
        url: '/aktualnosci/projekt-malinowe-faktory-2021',
        date: '16 sierpnia 2021', 
        titleShort: 'Projekt Malinowe Factory 2021',
        decription: 'Malinowe Factory miały miejsce w dniu 6 sierpnia w nowoczesnym gospodarstwie Agronom Berries w Pasiece koło Sosnowicy. Firma SmartGrow Sp. Z o. o. brała aktywny udział w tym wydarzeniu – mieliśmy swoje stoisko wśród innych wystawców a nasz główny technolog - wiceprezes Grzegorz Piech na forum wszystkich uczestników podczas konferencji w otoczeniu pięknej plantacji malin dokonał prezentacji Systemu SmartGrow.',
      },
      {
        id: '7820213',
        url: '/aktualnosci/system-zarzadzania-warunkami-uprawy-borowek',
        date: '16 sierpnia 2021', 
        titleShort: 'System zarządzania warunkami uprawy borówek',
        decription: 'W czerwcu 2021 roku w gospodarstwie dr Pawła Krawca z Horti Team doszło do kolejnej ciekawej rozmowy. Jej tematem był system zarządzania warunkami uprawy borówek. Rozwiązanie takie oferuje firma SmartGrow. Będzie ono testowo wdrażane na plantacjach, gdzie realizowane są projekty Borówkowe Factory i Malinowe Factory.',
      },
      {
        id: '7820214',
        url: '/aktualnosci/5-startupow-z-sektora-rolno-spozywczego',
        date: '12 września 2021', 
        titleShort: '5 startupów z sektora rolno-spożywczego, które warto obserwować',
        decription: 'Nasza firma Smartgrow Sp. z o. o znalazła się wśród 5 startapów z sektora rolno-spożywczego, które w skali Europy moją bardzo obiecującą i perspektywiczną innowację technologiczną na rynku agro.',
      },
      {
        id: '7820215',
        url: '/aktualnosci/sztuczna-inteligencja-uchroni-uprawy-przed-szkodnikami',
        date: '12 lutego 2020', 
        titleShort: 'Sztuczna inteligencja uchroni uprawy przed szkodnikami',
        decription: 'Nasza firma Smartgrow Sp. z o. o znalazła się wśród 5 startapów z sektora rolno-spożywczego, które w skali Europy moją bardzo obiecującą i perspektywiczną innowację technologiczną na rynku agro.',
      },
      
    ]
  }


  render() {
    
    const articles = this.state.articles.map(e => {
      return (
        <div class="px-5 py-4 my-4 border rounded-3" key={e.id}>
          <p class="text-muted">Dodano: {e.date}</p>
          <Link to={e.url} className="text-dark">
            <h2 className="fw-bold pb-2">{e.titleShort}</h2>
          </Link>
          <p className="lead fw-normal">
            {e.decription}
          </p>
          <p className="lead fw-light">
            
          </p>
        </div>
        )
        
    })
    return (
      <>
        <header className="py-5 bg-light border-bottom mb-4 news-hero">
          <div className="py-5 my-0 my-md-4 my-lg-5"></div>
        </header>
        <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">Aktualności</h1>

        <div class="container-lg p-lg-5">

          <ul>{articles}</ul>

        </div>

    </>
    )
  }
}

export default News