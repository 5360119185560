import React from "react";
import { Link } from "react-router-dom";

const IrrigationSystem = () => {
  return(
    <>
      <header className="py-5 bg-light border-bottom mb-4 news-hero">
        <div className="py-5 my-0 my-md-4 my-lg-5" />
      </header>
      <h1 class="fw-bolder text-center display-4 mt-5 py-5 divider">System nawadniania</h1>
      
      <div class="container-lg py-lg-5">
        

        <p className="h3 my-4">Nasza misja</p>
        <p className="lead fw-normal mt-4 mb-5">
          Dostarczyć plantatorowi precyzyjną  informację wspierającą podejmowanie optymalnej decyzji w uprawie roślin.
        </p>

        <hr />

        <p className="lead fw-normal mt-5 my-4">
          Realizując naszą misję, został utworzony cały system nawadniania poprzez zastosowani
          nowoczesnym i sprawdzonych urządzeń i narzędzi technologicznych, które odpowiednio ze
          sobą zestawione i współpracujące, są nieodzowne i niezawodne na plantacji.
        </p>

        <p className="lead fw-normal my-4">
          Smartgrow System Nawadniania – SSN 1 to komputer nawodnieniowy wraz z urządzeniami
          i pełnym orurowaniem plantacji, prosty w obsłudze i niezawodny w działaniu.
        </p>

        <p className="lead fw-normal my-4">
          Smartgrow System Nawadniania – SSN 1- to innowacyjny system zarządzania gospodarką
          wodną, rozwiązanie pozwalające na większą efektywność pracy rolnika, zarządzające
          procesem optymalizacji nawadniania i nawożenia w plantacjach pod osłonami, dzięki temu
          znacząco zmniejsza koszty produkcji upraw a jednocześnie plony są zwiększone.
        </p>
        <p className="lead fw-normal my-4">
          SmartGrow System Nawadniania to system optymalizacji dla rolnictwa - plantator
          oszczędza na kosztach produkcji roślinnej a także oszczędza czas czyli mniej wydaje
          pieniędzy w uprawach na swojej plantacji.
        </p>
        <p className="lead fw-normal my-4">
          Dzięki zastosowaniu naszego Systemu SmartGrow, plantator ma istotne wsparcie w swojej
          pracy, ponieważ otrzymuje precyzyjną informację, która wspiera go w podejmowaniu jak
          najlepszej decyzji w procesie uprawy roślin.
        </p>
        <p className="lead fw-normal my-4">
          Ta precyzyjna informacja dostarczana jest do plantatora na jego smartfon czy laptop w
          sposób ciągły, dzięki temu ma cały czas podgląd o sytuacji na swojej plantacji.
        </p>

        <p className="lead mt-5">
          Więcej o SmartGrow System Nawodnienia -SSN1 podczas szczegółowej prezentacji.
        </p>

        

      
      
      </div>


    </>
  )
}

export default IrrigationSystem;